import { ForwardedRef, forwardRef } from 'react'

import * as CSS from 'csstype'
import theme from 'utils/theme'

import Button, { IButtonProps, Variant } from '../'

const { colors } = theme

const textVariantFix = {
  textDecoration: 'none !important',
  '&:disabled': {
    color: 'inherit'
  }
}

const baseStyles: CSS.PropertiesFallback = {
  borderRadius: '8px'
}

/**
 * Need to override old theme styles
 * until all apps go live with branding updates
 */
export const setBtnStyles = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return {
        ...baseStyles,
        background: colors.primary300,
        color: colors.light,
        '&:hover': {
          background: colors.primary400
        },
        '&:active': {
          background: colors.primary500
        }
      }
    case 'text':
      return {
        ...baseStyles,
        color: colors.primary,
        ...textVariantFix
      }
    default:
      return baseStyles
  }
}

const BaseButtonRef = (
  {
    buttonSize,
    display,
    variant = 'primary',
    type,
    children,
    disabled,
    href,
    form,
    id,
    loading,
    name,
    newTab,
    onClick,
    value,
    brandColor,
    iconPlacement,
    textSize,
    padding,
    styles
  }: IButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => (
  <Button
    ref={ref}
    buttonSize={buttonSize}
    display={display}
    variant={variant}
    type={type}
    disabled={disabled}
    href={href}
    form={form}
    id={id}
    loading={loading}
    name={name}
    newTab={newTab}
    onClick={onClick}
    value={value}
    brandColor={brandColor}
    iconPlacement={iconPlacement}
    padding={padding}
    textSize={textSize}
    styles={{ ...setBtnStyles(variant), ...styles }}
  >
    {children}
  </Button>
)

const ButtonV2 = forwardRef(BaseButtonRef)

export default ButtonV2
